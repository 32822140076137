import React from 'react';

import { AiOutlineMenu } from 'react-icons/ai';
import { IconContext } from 'react-icons';

import UnlockModal from '../unlock/unlockModal.jsx';
import {
  // ERROR,
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  // CONFIGURE_RETURNED,
  COLLECTION_NAME,
} from '../../constants/constants';

import Store from '../../stores/store';
const { emitter, store } = Store;
class Header extends React.Component {
  constructor(props) {
    super(props);
    const account = store.getStore('account');
    const themeType = store.getStore('themeType');
    const activeClass = store.getStore('activeClass');

    this.state = {
      activeClass,
      loading: false,
      account: account,
      assets: store.getStore('assets'),
      modalOpen: false,
      themeType: themeType,
    };
  }

  componentWillMount() {
    emitter.on(CONNECTION_CONNECTED, this.connectionConnected);
    emitter.on(CONNECTION_DISCONNECTED, this.connectionDisconnected);
  }

  componentWillUnmount() {
    emitter.removeAllListeners();
  }

  connectionConnected = () => {
    this.setState({ account: store.getStore('account') });
  };

  configureReturned = () => {
    //.this.props.history.push('/')
  };

  connectionDisconnected = () => {
    this.setState({ account: store.getStore('account'), loading: false });
  };

  errorReturned = (error) => {
    //TODO: handle errors
  };

  renderModal = () => {
    return (
      <UnlockModal
        closeModal={this.closeModal}
        modalOpen={this.state.modalOpen}
      />
    );
  };

  unlockClicked = () => {
    this.setState({ modalOpen: true, loading: true });
  };

  closeModal = () => {
    this.setState({ modalOpen: false, loading: false });
  };

  render() {
    const { account, modalOpen } = this.state;
    var address = null;
    if (account?.address) {
      address =
        account.address.substring(0, 6) +
        '...' +
        account.address.substring(
          account.address.length - 4,
          account.address.length
        );
    }
    return (
      <>
        <nav className='navbar navbar-expand-lg'>
          <span style={{ width: '20%' }}>
            <span className='d-block d-sm-none' id='header-burger-menu-icon'>
              <IconContext.Provider value={{ size: '2em' }}>
                <AiOutlineMenu
                  data-bs-toggle='offcanvas'
                  data-bs-target='#mobileSideBar'
                  aria-controls='mobileSideBar'
                />
              </IconContext.Provider>
            </span>
          </span>
          <div className='d-flex justify-content-between social'>
            <a
              href={`https://opensea.io/collection/${COLLECTION_NAME}`}
              target='_blank'
            >
              Opensea
            </a>
            <a href='https://twitter.com/wegobattle' target='_blank'>
              <img
                src={require('../../assets/social/social-twitter.png')}
                className='img-fluid'
              />
            </a>
            {/* <a href='https://t.me/joinwego' target='_blank'>
              <img
                src={require('../../assets/social/social-telegram.png')}
                className='img-fluid'
              />
            </a> */}
            <a href='https://discord.gg/MDgmGTJFkD' target='_blank'>
              <img
                src={require('../../assets/social/social-discord.png')}
                className='img-fluid'
              />
            </a>
          </div>
          <div>
            {address && (
              <button
                className='btn btn-outline-primary btn-outline-gray round-30 px-3'
                onClick={this.unlockClicked}
              >
                <span className='mr-3'>
                  <b>Wallet</b>
                </span>
                <span>{address}</span>
              </button>
            )}
            {!address && (
              <button
                className='btn btn-outline-primary btn-outline-orange round-30 btn-lg'
                onClick={this.unlockClicked}
              >
                <h4 className='mb-0'>CONNECT</h4>
              </button>
            )}
          </div>
        </nav>

        {modalOpen && this.renderModal()}
      </>
    );
  }
}

export default Header;
