import development from './development.config';
import production from './production.config';
import example from './example.config';
const env = process.env.APP_ENV || 'example';

const config = {
  example,
  development,
  production,
};

// we export these for single use on some components and not importing the whole config in eg HOME.js
export const exchangeAddress = config[env]['exchangeAddress'];
export const erc20ABI = config[env]['erc20ABI'];
export const infuraProvider = config[env]['infuraProvider'];
export const AngelPodsAddress = config[env]['AngelPodsAddress'];
export const AngelPodsAbi = config[env]['AngelPodsAbi'];

export default config[env];
