import React, { Component } from 'react';

import { Switch, Route } from 'react-router-dom';
import IpfsRouter from 'ipfs-react-router';
// import Leftnav from './components/leftnav/leftnav';
import Header from './components/header/header';
import Mint from './components/mint/mint';
import SnackBar from './components/utils/snackbar';

import './i18n';

import Account from './components/account/account';
import Home from './components/home/home';
import Stake from './components/stake/stake';
import ScrollToTop from './components/utils/ScrollToTop';

import {
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  CONFIGURE,
  ERROR,
  HASH,
  CONFIGURE_RETURNED,
} from './constants';

import { injected } from './stores/connectors';

import Store from './stores/store';
const { emitter, dispatcher, store } = Store;

class App extends Component {
  state = {
    account: null,
    headerValue: null,
    themeType: false,
    style: 'dark-mode',
    snackBarOpen: false,
    snackBarSeverity: 'success',
    snackBarMessage: '',
    // init app will work as a checker for configure returned. we dont want to run anything before store gets initialized
    initApp: 0,
  };

  setHeaderValue = (newValue) => {
    this.setState({ headerValue: newValue });
  };

  inject = () => {
    injected
      .isAuthorized()
      .then((isAuthorized) => {
        console.log(isAuthorized);
        if (isAuthorized) {
          injected
            .activate()
            .then((a) => {
              store.setStore({
                account: { address: a.account },
                web3context: { library: { provider: a.provider } },
              });
              this.setState({ account: store.getStore('account'), initApp: 1 });

              console.log('injected authorized');
              emitter.emit(CONNECTION_CONNECTED);
              // console.log(a);
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          this.setState({ initApp: 1 });
        }
      })
      .catch((err) => {
        console.log('injected err', err);
      });
  };

  ethAccountChanged = () => {
    if (window.ethereum) {
      console.log('App.js set accountsChanged');
      window.ethereum.on('accountsChanged', (accounts) => {
        console.log('App.js got accountsChanged');
        store.setStore({
          account: { address: accounts[0] },
        });
        this.setState({ account: accounts[0] });
        console.log(accounts);
        window.location.reload();
        // this.inject();

        // this.forceUpdate();
        // Time to reload your interface with accounts[0]!
      });
    }
  };

  componentDidMount() {
    emitter.on(CONNECTION_CONNECTED, this.connectionConnected);
    emitter.on(CONNECTION_DISCONNECTED, this.connectionDisconnected);
    emitter.on(CONFIGURE_RETURNED, this.configureReturned);
    // emitter.on(GET_BALANCES_PERPETUAL_RETURNED, this.getBalancesReturned);
    emitter.on(ERROR, this.errorHandler);

    // this is the general success - may in the component be another behaviour
    emitter.on(HASH, this.hashHandler);

    let style = localStorage.getItem('theme');
    if (style) this.setState({ style });

    this.inject();
    this.ethAccountChanged();
  }

  componentWillUnmount() {
    emitter.removeAllListeners();
  }

  errorHandler = (error) => {
    console.log(error);
    this.setSnackBar(true, 'warning', error.message);
  };

  hashHandler = (res) => {
    const msg = 'Success ' + res.transactionHash ? res.transactionHash : '';
    this.setSnackBar(true, 'success', msg);
  };

  // getBalancesReturned = () => {
  //   window.setTimeout(() => {
  //     // dispatcher.dispatch({ type: GET_BALANCES_PERPETUAL, content: {} });
  //   }, 300000);
  // };

  configureReturned = () => {
    // this.setState({ account: store.getStore('account') });
    // this.setState({ initApp: 1 });
  };

  connectionConnected = () => {
    this.setState({ account: store.getStore('account'), initApp: 1 });

    console.log(store.getStore('account'));
    dispatcher.dispatch({ type: CONFIGURE, content: {} });
  };

  connectionDisconnected = () => {
    this.setState({ account: store.getStore('account') });
  };

  setSnackBar = (open, severity = 'info', message = '') => {
    this.setState({
      snackBarOpen: open,
      snackBarSeverity: severity,
      snackBarMessage: message,
    });
  };

  onSwitchThemeHandler = () => {
    let style = this.state.style === 'light-mode' ? 'dark-mode' : 'light-mode';
    localStorage.setItem('theme', style);
    this.setState({ style });
  };

  render() {
    const {
      account,
      snackBarMessage,
      snackBarOpen,
      snackBarSeverity,
      initApp,
    } = this.state;

    console.log('app js initApp', initApp);

    return (
      <div className={`main-content`}>
        <SnackBar
          open={snackBarOpen}
          setOpen={this.setSnackBar}
          message={snackBarMessage}
          severity={snackBarSeverity}
        />
        <IpfsRouter>
          <ScrollToTop />
          <Header />

          {/* <Leftnav
            onSwitchTheme={this.onSwitchThemeHandler}
            activeStyle={this.state.style}
          /> */}

          <Switch>
            {/* <Route
              path='/stake/:address'
              component={account ? Stake : Account}
            /> */}
            {/* <Route path='/hives' component={account ? Hives : Account} />
            <Route path='/faq' component={Faq} /> */}
            <Route path='/mint' component={account ? Mint : Account} />
            {/* <Route path='/resources' component={Resources} />
            <Route exact path='/pools/:selectedPool'>
              {account && (
                <Pools
                  assetsStoreKey='exchangeAssets'
                  extraAssets={['ETH', 'WPE']}
                  disableSwap={true}
                />
              )}
            </Route>
            <Route path='/pools'>
              {account && (
                <Pools
                  assetsStoreKey='exchangeAssets'
                  extraAssets={['ETH', 'WPE']}
                  disableSwap={true}
                />
              )}
            </Route>
            <Route
              exact
              path='/nft/:tokenAddress/:tokenId'
              component={NftDetails}
            />
            <Route exact path='/nft/:tokenAddress' component={Nft} />
            <Route path='/nft'>
              <Nft />
            </Route> */}
            {initApp ? (
              <Route path='/'>
                <Home test={this.state.initApp} />
              </Route>
            ) : null}
          </Switch>
        </IpfsRouter>
      </div>
    );
  }
}

export default App;
