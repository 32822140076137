import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { BsCheckBox } from 'react-icons/bs';
import { CgSandClock } from 'react-icons/cg';
import moment from 'moment';
import { IconContext } from 'react-icons';
import Slider from '../utils/slider';
import {
  BASE_PRICE,
  MAX_SUPPLY,
  COLLECTION_NAME,
} from '../../constants/constants';
import Store from '../../stores/store';
import { AngelPodsAbi, AngelPodsAddress } from '../../config/config';
// import Wall from '../utils/wall';

const { store } = Store;

const Home = (props) => {
  console.log('props.test', props.test);
  const [account, setAccount] = useState(store.getStore('account'));
  const [diffTime, setDiffTime] = useState();
  const [price, setPrice] = useState(BASE_PRICE);
  const [supplyAvailable, setTotalSupplyAvailable] = useState();

  const getTotalSupply = async () => {
    try {
      const p = await store.getVariable('price'); // 1629341013
      // const uri = await store.getVariable('tokenURI', 11);
      // console.log(uri);
      let paused = await store.getVariable('paused'); // 1629341013
      setPrice(p / 10 ** 18);
      console.log(price);
      let st = await store.getVariable('startTime'); // 1629341013
      let et = await store.getVariable('endTime'); // 1629409769
      if (st && et) {
        st = moment.unix(st);
        et = moment.unix(et);
        const diffTime = Math.ceil(moment.duration(et.diff(st)).asMinutes());
        // console.log(diffTime);
        setDiffTime(diffTime);
      }
      // console.log(st, et);
      const supply = await store.getTotalSupply({
        abi: AngelPodsAbi,
        address: AngelPodsAddress,
      });

      setTotalSupplyAvailable(MAX_SUPPLY - supply);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      console.log('home set accountsChanged');
      window.ethereum.on('accountsChanged', (accounts) => {
        console.log('home got accountsChanged');
        setAccount(accounts[0]);
        // window.location.reload();
      });
    }

    // const account = store.getStore('account');
    if (account?.address) getTotalSupply();
  });

  return (
    <div className='home-wrapper'>
      <div className='text-center home-first-section-bg home-first-section'>
        <img
          alt=''
          src={require('../../assets/wego/logo.png')}
          className='logo'
        />
        <p>
          WeGo is an epic BattleVerse where NFT craziest EARN as they set out
          for Crypto World domination!
          <br />
          Punks, APES, Lizards, Cats, Dogs, Friends, Foes, and Liars! All are
          welcome but will it be enough?
        </p>
        {account?.address ? (
          supplyAvailable > 0 ? (
            <Link
              to='/mint'
              className='btn btn-primary bg-theme mb-3 rounded-pill border-none px-5'
            >
              Mint your Pods
            </Link>
          ) : null
        ) : null}
        <div className='row m-0'>
          <div className='col-md-4 offset-md-4'>
            <div className='btn btn-primary bg-theme border-none mr-1'>
              <span className='text-sm'>Pods left</span>
              <div>
                {account?.address ? supplyAvailable : 'connect your wallet'}
              </div>
            </div>
            <div className='btn btn-primary bg-theme border-none'>
              <span className='text-sm'>price</span>
              <div>{price}ETH</div>
            </div>
            {supplyAvailable === 0 ? (
              <>
                <br />
                <a
                  href={`https://opensea.io/collection/${COLLECTION_NAME}`}
                  target='_blank'
                  className='btn btn-primary mt-3 btn- rounded-pill bg-theme border-none px-5'
                >
                  Sold out!
                  <div>
                    Buy on <u>Opensea</u>
                  </div>
                </a>
                <div>{diffTime ? `Sold out in ${diffTime} minutes` : null}</div>
              </>
            ) : null}

            {/* <div className='row'>
                  <div className='col-md-6'>
                  </div>
                  <div className='col-md-6'>
                    <div className='btn btn-primary'>
                      <span className='text-sm'>price</span>
                      <div>0.06ETH</div>
                    </div>
                  </div>
                </div> */}
          </div>
        </div>
      </div>
      <Slider />
      <div className='p-5'>
        <div className='row'>
          <div className='col-md-8 offset-md-2 col-xs-12 fs-3 text-center'>
            <p>
              Play the deadly game to control an NFT kingdom in the WeGo
              BattleVerse! Will your tribe rise to the TOP and show your pride,
              or are you really lazy? Are you Punks headed for the Junkyard,
              Pudgy Strays being put out to pasture, or already dead inside with
              no community to rally for the WIN?
            </p>
            <p>Where is the strongest community of NFTs?</p>
            WeGo Battle it OUT!
          </div>
        </div>
      </div>
      <div className='roadmap'>
        <div className='p-3 p-sm-5'>
          <h1 className='text-center'>Roadmap to Launch</h1>
          <div className='row'>
            <div className='mb-3 col-12 text-center'>
              <p className='common-p'>
                The Community Will Launch WeGo
                <br />
                Join WeGo Twitter Channel{' '}
                <a href='https://twitter.com/wegobattle'>
                  https://twitter.com/wegobattle
                </a>
                <br />
                The revealing of WeGo Battle Golden Information Nuggets
              </p>
            </div>
            <div className='col-md-10 offset-md-1'>
              <table className='table'>
                <tbody>
                  <tr>
                    <th scope='row'>Twitter Followers</th>
                    <td>Event</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope='row'>5,000</th>
                    <td>
                      <a
                        href='https://twitter.com/wegobattle/status/1435341900224081923'
                        target='_blank'
                      >
                        1st Nugget revealed!
                      </a>
                    </td>
                    <td>
                      <IconContext.Provider value={{ size: '1.2em', color: 'green' }}>
                        <BsCheckBox />
                      </IconContext.Provider>
                    </td>
                  </tr>
                  <tr>
                    <th scope='row'>15,000</th>
                    <td>
                      <a
                        href='https://twitter.com/wegobattle/status/1437279242094587904'
                        target='_blank'
                      >
                        2nd Nugget revealed!
                      </a>
                    </td>
                    <td>
                      <IconContext.Provider value={{ size: '1.2em', color: 'green' }}>
                        <BsCheckBox />
                      </IconContext.Provider>
                    </td>
                  </tr>
                  <tr>
                    <th scope='row'>25,000</th>
                    <td>
                      <a
                        href='https://twitter.com/wegobattle/status/1439612840751534081'
                        target='_blank'
                      >
                        3rd Nugget revealed!
                      </a>
                    </td>
                    <td>
                      <IconContext.Provider value={{ size: '1.2em', color: 'green' }}>
                        <BsCheckBox />
                      </IconContext.Provider>
                    </td>
                  </tr>
                  <tr>
                    <th scope='row'>35,000</th>
                    <td>
                      <a
                        href='https://twitter.com/wegobattle/status/1443339782147481601'
                        target='_blank'
                      >
                        4th Nugget revealed!
                      </a>
                    </td>
                    <td>
                      <IconContext.Provider value={{ size: '1.2em', color: 'green' }}>
                        <BsCheckBox />
                      </IconContext.Provider>
                    </td>
                  </tr>
                  <tr>
                    <th scope='row'>50,000</th>
                    <td>Reveal Golden Information Nugget</td>
                    <td>
                      {/* <BsCheckBox /> */}
                      <CgSandClock />
                    </td>
                  </tr>
                  <tr>
                    <th scope='row'>75,000</th>
                    <td>Reveal Golden Information Nugget</td>
                    <td>
                      {/* <BsCheckBox /> */}
                      <CgSandClock />
                    </td>
                  </tr>
                  <tr>
                    <th scope='row'>100,000</th>
                    <td>Reveal Golden Information Nugget</td>
                    <td>
                      {/* <BsCheckBox /> */}
                      <CgSandClock />
                    </td>
                  </tr>
                  <tr>
                    <th scope='row'>125,000</th>
                    <td>Reveal Golden Information Nugget</td>
                    <td>
                      {/* <BsCheckBox /> */}
                      <CgSandClock />
                    </td>
                  </tr>
                  <tr>
                    <th scope='row'>150,000</th>
                    <td>Reveal Golden Information Nugget</td>
                    <td>
                      {/* <BsCheckBox /> */}
                      <CgSandClock />
                    </td>
                  </tr>
                  <tr>
                    <th scope='row'>200,000</th>
                    <td>WeGo community launches WeGoBattle</td>
                    <td>
                      {/* <BsCheckBox /> */}
                      <CgSandClock />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* <div className='col-md-10 offset-md-1 mt-5'>
              <hr />
              <Wall />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(withRouter(Home));
