import React, { Component } from 'react';
import Slider from 'react-slick';

export default function ImageSlider() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    arrows: false,
    // adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 415,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const images = [
    'pod121.png',
    'pod548.png',
    'pod1499.png',
    'pod1920.png',
    'pod121.png',
    'pod548.png',
    'pod1499.png',
    'pod1920.png',
  ];
  return (
    <div>
      <Slider {...settings}>
        {images.map((i) => {
          return (
            <div key={i}>
              <img
                src={require(`../../assets/wego/nfts/${i}`)}
                className='slider-img'
                alt=''
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
