import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  const listener = (e) => {
    let scrolled = document.scrollingElement.scrollTop;
    // console.log(scrolled);
    if (scrolled < 295) {
      document
        .getElementById('header-burger-menu-icon')
        .classList.add('color-white');
    } else {
      document
        .getElementById('header-burger-menu-icon')
        .classList.remove('color-white');
    }
  };

  useEffect(() => {
    // console.log(pathname, pathname === '/');
    window.scrollTo(0, 0);

    // document.removeEventListener('scroll', listener);

    if (pathname === '/') {
      document.addEventListener('scroll', listener);
      document
        .getElementById('header-burger-menu-icon')
        .classList.add('color-white');
    } else {
      document
        .getElementById('header-burger-menu-icon')
        .classList.remove('color-white');
      // document.removeEventListener('scroll', listener);
    }

    return () => {
      document.removeEventListener('scroll', listener);
    };
  }, [pathname]);

  return null;
}
