import React, { useState, useEffect } from 'react';
// import { IoSwapVerticalOutline } from 'react-icons/io5';
// import { IconContext } from 'react-icons';
import {
  // InputGroup, Dropdown,
  Form,
} from 'react-bootstrap';

import moment from 'moment';

import {
  BASE_PRICE,
  MAX_SUPPLY,
  MAX_MINTEABLE,
  COLLECTION_NAME,
} from '../../constants/constants';
import Slider from '../utils/slider';

import './mint.scss';
import Store from '../../stores/store';
import { AngelPodsAbi, AngelPodsAddress } from '../../config/config';
// ERROR,
// EXCHANGE_RETURNED,
// EXIT,
// EXIT_RETURNED,

const { store } = Store;

const Mint = ({}) => {
  const [fromOptions, setFromOptions] = useState(
    store.getStore('exchangeAssets').tokens.filter((a) => a.group == 'inputs')
  );
  const [nftQty, setNftQty] = useState('1');
  const [fromAddress, setFromAddress] = useState(fromOptions[0].address);
  // const [toAmount, setToAmount] = useState('0');
  // const [toAddress, setToAddress] = useState(toOptions[0].address);
  const [error, setError] = useState('');

  const [price, setPrice] = useState(BASE_PRICE);

  const [fromToggleContents, setFromToggleContents] = useState('Choose');
  // const [toToggleContents, setToToggleContents] = useState('Choose');

  const [doingTransaction, setDoingTransaction] = useState(false);
  const [selectedAssetBalance, setSelectedAssetBalance] = useState(0);

  const [supplyAvailable, setTotalSupplyAvailable] = useState();
  const [diffTime, setDiffTime] = useState();

  const getTotalSupply = async () => {
    try {
      // console.log(AngelPodsAbi);
      // console.log(AngelPodsAddress);
      const supply = await store.getTotalSupply({
        abi: AngelPodsAbi,
        address: AngelPodsAddress,
      });

      console.log(supply);

      let st = await store.getVariable('startTime'); // 1629341013
      let et = await store.getVariable('endTime'); // 1629409769
      if (st && et) {
        st = moment.unix(st);
        et = moment.unix(et);
        const diffTime = Math.ceil(moment.duration(et.diff(st)).asMinutes());
        console.log(diffTime);
        setDiffTime(diffTime);
      }
      console.log(st, et);

      setTotalSupplyAvailable(MAX_SUPPLY - supply);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTotalSupply();
    // default option
    onSelectAssetIn(fromAddress);
    // onSelectAssetOut(toAddress);
  }, []);

  // useEffect(() => {
  //   // we dont need these handlers as
  //   // emitter.on(ERROR, handleError);
  //   // emitter.on(MINT_NFTS_RETURNED, handleSuccess);

  //   return () => {
  //     // emitter.removeListener(ERROR, handleError);
  //     // emitter.removeListener(MINT_NFTS_RETURNED, handleSuccess);
  //   };
  // }, []);

  useEffect(() => {
    const newPrice = BASE_PRICE * nftQty;
    setPrice(newPrice);
  }, [nftQty]);

  const handleError = (err) => {
    console.log('err ----------', err);
    setDoingTransaction(false);
    setError('');
  };

  const handleSuccess = (res) => {
    console.log('res ----------', res);
    setDoingTransaction(false);
    setNftQty('1');
    setError('');
  };

  // const getUnitPrice = async () => {
  //   let assetIn, amountOut, assetOut;

  //   if (fromAddress)
  //     assetIn = fromOptions.find((i) => i.address == fromAddress);
  //   if (toAddress) assetOut = toOptions.find((i) => i.address == toAddress);

  //   amountOut = await store.getAmountOut(assetIn, assetOut, '1');
  //   console.log(assetIn);
  //   setUnitPrice(`1 ${assetIn.label} = ${amountOut} ${assetOut.label}`);
  // };

  const onChangeQty = async (amountIn) => {
    setError('');
    setNftQty(amountIn);
  };

  const onChangeFromSelect = (value) => {
    setError('');
    setFromAddress(value);
  };

  // const onChangeToSelect = (value) => {
  //   setError('');
  //   setToAddress(value);
  // };

  const onSelectAssetIn = async (eventKey) => {
    const token = fromOptions.find(({ address }) => eventKey === address);
    const { label, address, logo } = token;

    setSelectedAssetBalance(await store.getAssetBalance(token));

    onChangeFromSelect(address);
    setFromToggleContents(
      <>
        <img
          style={{
            maxHeight: '22px',
            marginRight: '5px',
          }}
          src={require(`../../assets/logos/${logo}`)}
          alt=''
        />
        {label}
      </>
    );
  };

  // const onSelectAssetOut = (eventKey) => {
  //   const token = toOptions.find(({ address }) => eventKey === address);
  //   const { label, address, logo } = token;
  //   onChangeAssetForChart(token.tradingViewKey);
  //   onChangeToSelect(address);
  //   setToToggleContents(
  //     <>
  //       <img
  //         style={{
  //           maxHeight: '22px',
  //           marginRight: '5px',
  //         }}
  //         src={require(`../../assets/logos/${logo}`)}
  //         alt=''
  //       />
  //       {label}
  //     </>
  //   );
  // };

  const onCreateTransaction = async () => {
    if (selectedAssetBalance < price) return setError('Not enough Eth');

    console.log('minting ------------');

    setDoingTransaction(true);

    //this.setState({ loading: true });
    try {
      let res = await store.mintNfts({
        content: { quantity: nftQty },
      });
      console.log(res);
      getTotalSupply();
    } catch (error) {}
    setDoingTransaction(false);
    // dispatcher is overkill
    // dispatcher.dispatch({
    //   type: MINT_NFTS,
    //   content: {
    //     quantity: nftQty,
    //   },
    // });
  };

  return (
    <>
      <div className='mint-container'>
        <div className='row m-0'>
          <div className='col-md-6 offset-md-3'>
            <div className='exchange-wrapper card'>
              <div className='card-body'>
                <h4 className='text-center mb-4'>Mint your WeGo Pods</h4>

                {/* <div className='d-flex justify-content-between align-items-end'>
                <span className='pull-right mb-1 text-muted text-sm'>
                  Your balance: {selectedAssetBalance?.toFixed(4)}
                </span>
              </div> */}

                <div className='d-flex justify-content-between'>
                  <Form.Control
                    className='border-none'
                    style={{ width: '50%', backgroundColor: '#e9ecef' }}
                    type='number'
                    step='1'
                    min='1'
                    max={
                      supplyAvailable < MAX_MINTEABLE
                        ? `${supplyAvailable}`
                        : `${MAX_MINTEABLE}`
                    }
                    value={nftQty}
                    onChange={(e) => onChangeQty(e.target.value)}
                    aria-describedby='basic-addon1'
                  />

                  <div className='d-flex justify-content-between'>
                    <img
                      style={{
                        maxHeight: '3rem',
                        marginRight: '5px',
                      }}
                      src={require(`../../assets/logos/logo-eth.png`)}
                      alt=''
                    />
                    <div>
                      {price.toFixed(4)}
                      <div>Eth</div>
                    </div>
                  </div>
                </div>
                <div className='text-right mb-1 text-muted text-sm'>
                  Your Eth balance: {selectedAssetBalance?.toFixed(4)}
                </div>

                <div className='text-center'>
                  {error && error.length && <div>{error}</div>}
                  {supplyAvailable == 0 ? (
                    <>
                      <button className='btn btn-primary mt-3 btn-lg rounded-pill bg-theme border-none px-5'>
                        Sold Out!
                      </button>
                      <div>
                        {diffTime ? `Sold out in ${diffTime} minutes` : null}
                      </div>
                      <h5 className='text-muted'>
                        Visit us on{' '}
                        <a
                          href={`https://opensea.io/collection/${COLLECTION_NAME}`}
                          target='_blank'
                        >
                          opensea
                        </a>{' '}
                        to buy existing.
                      </h5>
                    </>
                  ) : (
                    <>
                      <button
                        className='btn btn-primary mt-3 btn-lg rounded-pill bg-theme border-none px-5'
                        disabled={(error && error.length) || doingTransaction}
                        onClick={onCreateTransaction}
                      >
                        {doingTransaction ? 'loading ...' : 'Mint WeGo Pods'}
                      </button>
                      <div className='text-sm text-muted'>
                        10 limit per transaction
                      </div>
                      <div className='text-sm text-muted'>
                        {supplyAvailable}/{MAX_SUPPLY} available
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Slider />
    </>
  );
};

export default Mint;
